export const educationNode = [

    {
        role: "Node Web Developer",
        pathName: "Working with Node.js",
        level: "Proficient",
        points: 156,
        icon: "https://pluralsight.imgix.net/paths/path-icons/nodejs-601628d09d.png"
    },
    {
        role: "Node Web Developer",
        pathName: "NPM",
        level: "Expert",
        points: 216,
        icon: "https://pluralsight2.imgix.net/assessments/images/icons/npm-EQLkhQpQW4Pa.png"
    },
    {
        role: "Node Web Developer",
        pathName: "JavaScript Core Language",
        level: "Proficient",
        points: 152,
        icon: "https://pluralsight2.imgix.net/paths/images/javascript-542e10ea6e.png"
    },
    {
        role: "Node Web Developer",
        pathName: "Managing Source Code with Git",
        level: "Expert",
        points: 204,
        icon: "https://pluralsight2.imgix.net/paths/images/macmanagementbasics-17fcd0c81c.png"
    },
    {
        role: "Node Web Developer",
        pathName: "Web Application Security",
        level: "Proficient",
        points: 118,
        icon: "https://pluralsight2.imgix.net/paths/images/comptia-security-plus-5d8ab8e621.png"
    },
    {
        role: "Node Web Developer",
        pathName: "NoSQL Databases - Mongo",
        level: "Proficient",
        points: 122,
        icon: "https://pluralsight2.imgix.net/assessments/images/icons/mongodb-_tapUCul3mGt.png"
    },
    {
        role: "Node Web Developer",
        pathName: "Express",
        level: "Proficient",
        points: 161,
        icon: "https://pluralsight2.imgix.net/assessments/images/icons/express-HM55YVk3-FU-.png"
    },
    {
        role: "Node Web Developer",
        pathName: "HTML5",
        level: "Proficient",
        points: 161,
        icon: "https://pluralsight.imgix.net/paths/path-icons/html5-7ac65c8ec0.png"
    },
    {
        role: "Node Web Developer",
        pathName: "CSS",
        level: "Proficient",
        points: 108,
        icon: "https://pluralsight.imgix.net/paths/path-icons/css-c9b214f0d7.png"
    }


]

export const educationReact = [

    {
        role: "React Web Developer",
        pathName: "Building Web Applications With React\n",
        level: "Proficient",
        points: 110,
        icon: "https://pluralsight2.imgix.net/paths/images/react-0f6558f88f.png"
    },
    {
        role: "React Web Developer",
        pathName: "JavaScript Core Language",
        level: "Proficient",
        points: 152,
        icon: "https://pluralsight2.imgix.net/paths/images/javascript-542e10ea6e.png"
    },
    {
        role: "React Web Developer",
        pathName: "HTML5",
        level: "Proficient",
        points: 161,
        icon: "https://pluralsight.imgix.net/paths/path-icons/html5-7ac65c8ec0.png"
    },
    {
        role: "React Web Developer",
        pathName: "CSS",
        level: "Proficient",
        points: 108,
        icon: "https://pluralsight.imgix.net/paths/path-icons/css-c9b214f0d7.png"
    },
    {
        role: "React Web Developer",
        pathName: "Redux",
        level: "Novice",
        points: 86,
        icon: "https://pluralsight2.imgix.net/assessments/images/icons/redux-NE5jFOfNfPe2.png"
    },
    {
        role: "React Web Developer",
        pathName: "Managing Source Code with Git",
        level: "Expert",
        points: 204,
        icon: "https://pluralsight2.imgix.net/paths/images/macmanagementbasics-17fcd0c81c.png"
    },
    {
        role: "React Web Developer",
        pathName: "NPM",
        level: "Expert",
        points: 216,
        icon: "https://pluralsight2.imgix.net/assessments/images/icons/npm-EQLkhQpQW4Pa.png"
    },
    {
        role: "React Web Developer",
        pathName: "ES6",
        level: "Novice",
        points: 86,
        icon: "https://pluralsight2.imgix.net/assessments/images/icons/es6-ZTFHaqZcD2zV.png"
    },
    {
        role: "React Web Developer",
        pathName: "TypeScript Core Language",
        level: "Proficient",
        points: 110,
        icon: "https://pluralsight2.imgix.net/paths/images/typescript-1169369a3e.png"
    },

]



export const educationBIAnalyst = [
    {
        role: "BI / Data Analyst (Microsoft Self-Service)",
        pathName: "Microsoft Excel Programming",
        level: "Expert",
        points: 210,
        icon: "https://pluralsight2.imgix.net/paths/images/comptia-storage-plus-ee0dacafe8.png"
    },
    {
        role: "BI / Data Analyst (Microsoft Self-Service)",
        pathName: "Microsoft Excel Data Insights",
        level: "Expert",
        points: 228,
        icon: "https://pluralsight2.imgix.net/assessments/images/icons/data-1U856Y56fJtq.png"
    },
    {
        role: "BI / Data Analyst (Microsoft Self-Service)",
        pathName: "Microsoft Excel Data Manipulation, Analysis, and Presentation",
        level: "Proficient",
        points: 166,
        icon: "https://pluralsight2.imgix.net/paths/images/comptia-storage-plus-ee0dacafe8.png"
    },
    {
        role: "BI / Data Analyst (Microsoft Self-Service)",
        pathName: "Microsoft Power BI for Analysts",
        level: "Proficient",
        points: 146,
        icon: "https://pluralsight2.imgix.net/paths/images/comptia-storage-plus-ee0dacafe8.png"
    },
    {
        role: "BI / Data Analyst (Microsoft Self-Service)",
        pathName: "Data Visualization Literacy",
        level: "Proficient",
        points: 139,
        icon: "https://pluralsight2.imgix.net/assessments/images/icons/data-1U856Y56fJtq.png"
    },
    {
        role: "BI / Data Analyst (Microsoft Self-Service)",
        pathName: "Data Analytics Literacy\n",
        level: "Novice",
        points: 83,
        icon: "https://pluralsight2.imgix.net/paths/images/macmanagementbasics-17fcd0c81c.png"
    },

]
